<!--Start topbar header-->
<header class="topbar-nav">
	<nav id="header-setting" class="navbar navbar-expand fixed-top">
		<div class="toggle-menu" (click)="toggleSidebar()"> <i class="zmdi zmdi-menu"></i>
		</div>
		<div class="search-bar flex-grow-1">
			<div class="input-group">
				<div class="input-group-prepend search-arrow-back">
					<button class="btn btn-search-back" type="button"><i class="zmdi zmdi-long-arrow-left"></i>
					</button>
				</div>
				<input type="text" class="form-control" placeholder="search">
				<div class="input-group-append">
					<button class="btn btn-search" type="button"><i class="zmdi zmdi-search"></i>
					</button>
				</div>
			</div>
		</div>
		<ul class="navbar-nav align-items-center right-nav-link ml-auto">
			<li class="nav-item dropdown search-btn-mobile">
				<a class="nav-link position-relative" href="javascript:;"> <i class="zmdi zmdi-search align-middle"></i>
				</a>
			</li>
			<li ngbDropdown class="nav-item dropdown-lg">
				<a class="nav-link dropdown-toggle-nocaret position-relative" href="javascript:;" id="dropdownBasic1" ngbDropdownToggle> <i class="zmdi zmdi-comment-outline align-middle"></i><span class="bg-danger text-white badge-up">12</span>
				</a>
				<div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-menu-right">
					<ul class="list-group list-group-flush">
						<li class="list-group-item d-flex justify-content-between align-items-center">New Messages <a href="javascript:;" class="extra-small-font">Clear All</a>
						</li>
						<li class="list-group-item">
							<a href="javaScript:;">
								<div class="media">
									<div class="avatar">
										<img class="align-self-start mr-3" src="assets/images/avatars/avatar-5.png" alt="user avatar">
									</div>
									<div class="media-body">
										<h6 class="mt-0 msg-title">Jhon Deo</h6>
										<p class="msg-info">Lorem ipsum dolor sit amet...</p> <small>Today, 4:10 PM</small>
									</div>
								</div>
							</a>
						</li>
						<li class="list-group-item">
							<a href="javaScript:;">
								<div class="media">
									<div class="avatar">
										<img class="align-self-start mr-3" src="assets/images/avatars/avatar-6.png" alt="user avatar">
									</div>
									<div class="media-body">
										<h6 class="mt-0 msg-title">Sara Jen</h6>
										<p class="msg-info">Lorem ipsum dolor sit amet...</p> <small>Yesterday, 8:30 AM</small>
									</div>
								</div>
							</a>
						</li>
						<li class="list-group-item">
							<a href="javaScript:;">
								<div class="media">
									<div class="avatar">
										<img class="align-self-start mr-3" src="assets/images/avatars/avatar-7.png" alt="user avatar">
									</div>
									<div class="media-body">
										<h6 class="mt-0 msg-title">Dannish Josh</h6>
										<p class="msg-info">Lorem ipsum dolor sit amet...</p> <small>5/11/2018, 2:50 PM</small>
									</div>
								</div>
							</a>
						</li>
						<li class="list-group-item">
							<a href="javaScript:;">
								<div class="media">
									<div class="avatar">
										<img class="align-self-start mr-3" src="assets/images/avatars/avatar-8.png" alt="user avatar">
									</div>
									<div class="media-body">
										<h6 class="mt-0 msg-title">Katrina Mccoy</h6>
										<p class="msg-info">Lorem ipsum dolor sit amet.</p> <small>1/11/2018, 2:50 PM</small>
									</div>
								</div>
							</a>
						</li>
						<li class="list-group-item text-center"><a href="javaScript:;">See All Messages</a>
						</li>
					</ul>
				</div>
			</li>
			<li ngbDropdown class="nav-item dropdown-lg">
				<a class="nav-link dropdown-toggle-nocaret position-relative" href="javascript:;" id="dropdownBasic2" ngbDropdownToggle> <i class="zmdi zmdi-notifications-active align-middle"></i><span class="bg-info text-white badge-up">14</span>
				</a>
				<div ngbDropdownMenu aria-labelledby="dropdownBasic2" class="dropdown-menu-right">
					<ul class="list-group list-group-flush">
						<li class="list-group-item d-flex justify-content-between align-items-center">New Notifications <a href="javascript:;" class="extra-small-font">Clear All</a>
						</li>
						<li class="list-group-item">
							<a href="javaScript:;">
								<div class="media"> <i class="zmdi zmdi-accounts fa-2x mr-3 text-info"></i>
									<div class="media-body">
										<h6 class="mt-0 msg-title">New Registered Users</h6>
										<p class="msg-info">Lorem ipsum dolor sit amet...</p>
									</div>
								</div>
							</a>
						</li>
						<li class="list-group-item">
							<a href="javaScript:;">
								<div class="media"> <i class="zmdi zmdi-coffee fa-2x mr-3 text-warning"></i>
									<div class="media-body">
										<h6 class="mt-0 msg-title">New Received Orders</h6>
										<p class="msg-info">Lorem ipsum dolor sit amet...</p>
									</div>
								</div>
							</a>
						</li>
						<li class="list-group-item">
							<a href="javaScript:;">
								<div class="media"> <i class="zmdi zmdi-notifications-active fa-2x mr-3 text-danger"></i>
									<div class="media-body">
										<h6 class="mt-0 msg-title">New Updates</h6>
										<p class="msg-info">Lorem ipsum dolor sit amet...</p>
									</div>
								</div>
							</a>
						</li>
						<li class="list-group-item text-center"><a href="javaScript:;">See All Notifications</a>
						</li>
					</ul>
				</div>
			</li>
			<li ngbDropdown class="nav-item language"> <a class="nav-link dropdown-toggle-nocaret position-relative" href="javascript:;" id="dropdownBasic3" ngbDropdownToggle><i class="flag-icon flag-icon-gb align-middle"></i></a>
				<ul ngbDropdownMenu aria-labelledby="dropdownBasic3" class="dropdown-menu-right">
					<li ngbDropdownItem><i class="flag-icon flag-icon-gb mr-3"></i>English</li>
					<li ngbDropdownItem><i class="flag-icon flag-icon-fr mr-3"></i>French</li>
					<li ngbDropdownItem><i class="flag-icon flag-icon-cn mr-3"></i>Chinese</li>
					<li ngbDropdownItem><i class="flag-icon flag-icon-de mr-3"></i>German</li>
				</ul>
			</li>
			<li ngbDropdown class="nav-item">
				<a class="nav-link dropdown-toggle-nocaret position-relative" href="javascript:;" id="dropdownBasic4" ngbDropdownToggle> <span class="user-profile"><img src="assets/images/avatars/avatar-13.png" class="img-circle" alt="user avatar"></span>
				</a>
				<ul ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-menu-right">
					<li ngbDropdownItem class="user-details">
						<a href="javaScript:;">
							<div class="media">
								<div class="avatar">
									<img class="align-self-start mr-3" src="assets/images/avatars/avatar-13.png" alt="user avatar">
								</div>
								<div class="media-body">
									<h6 class="mt-2 user-title">Sarajhon Mccoy</h6>
									<p class="user-subtitle">mccoy@example.com</p>
								</div>
							</div>
						</a>
					</li>
					<li class="dropdown-divider"></li>
					<li ngbDropdownItem><i class="zmdi zmdi-comments mr-3"></i>Inbox</li>
					<li class="dropdown-divider"></li>
					<li ngbDropdownItem><i class="zmdi zmdi-balance-wallet mr-3"></i>Account</li>
					<li class="dropdown-divider"></li>
					<li ngbDropdownItem><i class="zmdi zmdi-settings mr-3"></i>Setting</li>
					<li class="dropdown-divider"></li>
					<li ngbDropdownItem><i class="zmdi zmdi-power mr-3"></i>Logout</li>
				</ul>
			</li>
		</ul>
	</nav>
</header>
<!--End topbar header-->